<template>
  <div class="benefits-container">
    <section id="benefits">
      <div class="benefit" v-for="(benefit, index) in benefits" :key="index">
        <img :src="benefit.icon" :alt="benefit.title" />
        <h2 v-html="benefit.title"></h2>
        <p v-html="benefit.text"></p>
      </div>
      <div class="action">
        <router-link
          :to="{ name: 'create-hfa-account', params: { type: 'apply' } }"
          class="button primary shadow"
          >APPLY TODAY</router-link
        >
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "AffiliateBenefits",
  data: function() {
    return {
      benefits: [
        {
          title: "Worldwide Representation & Collections",
          text:
            "Earn more through our network of international society agreements and Mint Digital Services, our international multi-territory licensing online licensing organization.",
          icon: require("../assets/globe.png")
        },
        {
          title: "Expanded Deals & Compliance Audits",
          text:
            "HFA actively seeks deals to expand<br>royalty sources. HFA also participates in<br>compliance audits of licensed music distributors.",
          icon: require("../assets/expand.png")
        },
        {
          title: "Consolidated Royalty Payments & Reporting",
          text:
            "HFA has developed the powerful online tools and detailed income tracking based upon years of publisher input.",
          icon: require("../assets/consolidated-royalty-payment.png")
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.benefits-container {
  display: flex;
  justify-content: flex-end;

  #benefits {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    width: calc(100vw - 16px);
    max-width: 1500px;
    padding: 0 8px 30vh;
    margin-left: -8px;
    margin-right: -8px;
    background-color: $blue;

    .action {
      width: 100%;
      margin-top: -20px;

      .button {
        margin: 0 auto;
      }
    }
    .benefit {
      text-align: center;
      width: 100%;
      max-width: 380px;
      margin-bottom: 60px;

      img {
        width: 100px;
        display: inline-block;
      }
      h2 {
        font-family: "Fjalla One", sans-serif;
        font-size: 19px;
      }
      p {
        font-size: 19px;
      }
    }

    @media (min-width: $desktop) {
      flex-direction: row;
      margin-bottom: 30vh;
      width: auto;
      margin-left: 0;
      margin-right: 0;
      padding: 0;
      background-color: transparent;

      .benefit {
        width: 40%;
        max-width: none;
      }
    }
  }
}
</style>
