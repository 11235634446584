<template>
  <div id="create-hfa-account">
    <div class="header" v-show="!showPopup">
      <h1 v-if="$route.name == 'create-hfa-account'">
        TO APPLY TO BECOME AN HFA AFFILIATE PLEASE COMPLETE THIS FORM
      </h1>
      <h1 v-else>
        COMPLETE THIS FORM TO CREATE AN HFA ONLINE ACCOUNT
      </h1>
      <h2>
        With your HFA Online Account you will be able to Register Your Works,
        Get Paid & Access Royalty Statements
      </h2>
    </div>

    <div class="body" v-show="!showPopup">
      <div class="step publisher-information" v-show="step == 1">
        <div class="group">
          <h3>Publisher Information</h3>

          <div class="form-group">
            <label for="company_name">Name of Publishing Company*</label>
            <InputField
              name="company_name"
              :value="user.company_name"
              @update:value="user.company_name = $event"
              label="Name of Publishing Company"
              :required="true"
              :errors="errors"
            />
          </div>
          <div class="form-group" style="margin-bottom: 20px;">
            <label for="hfa_account_number">HFA Publisher Account No.</label>
            <InputField
              name="hfa_account_number"
              :value="user.hfa_account_number"
              @update:value="user.hfa_account_number = $event"
              label="HFA Publisher Account No."
              :required="false"
              :errors="errors"
              @focus="showAlert"
            />
            <div class="hint">
              (optional for existing HFA-registered publishers - number located
              on your payment voucher and begins with "P")
            </div>
          </div>
          <div class="form-group" style="margin-bottom: 20px;">
            <label for="ipi_number">IPI No.</label>
            <InputField
              name="ipi_number"
              :value="user.ipi_number"
              @update:value="user.ipi_number = $event"
              label="IPI No."
              :required="false"
              :errors="errors"
            />
            <div class="hint" v-show="!hasError('ipi_number')">
              (required for international collection - assigned by your
              Performing Rights Organization)
            </div>
          </div>
          <div class="form-group">
            <label for="accountOwner">
              Full name of individual who will use this account*
            </label>
            <div class="full-name">
              <label for="firstname">First name*</label>
              <InputField
                name="firstname"
                :value="user.firstname"
                @update:value="user.firstname = $event"
                label="First name"
                :required="true"
                :errors="errors"
              />
              <label for="firstname">Last name*</label>
              <InputField
                name="lastname"
                :value="user.lastname"
                @update:value="user.lastname = $event"
                label="Last name"
                :required="true"
                :errors="errors"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="business_title">Business title of account user*</label>
            <InputField
              name="business_title"
              :value="user.business_title"
              @update:value="user.business_title = $event"
              label="Business title of account user"
              :required="true"
              :errors="errors"
            />
          </div>
        </div>

        <div class="group">
          <h3>Administrator Contact Information</h3>

          <div class="form-group">
            <label for="address_line_1">Mailing Address 1*</label>
            <InputField
              name="address_line_1"
              :value="user.address_line_1"
              @update:value="user.address_line_1 = $event"
              label="Mailing Address 1"
              :required="true"
              :errors="errors"
            />
          </div>
          <div class="form-group">
            <label for="address_line_2">Mailing Address 2</label>
            <InputField
              name="address_line_2"
              :value="user.address_line_2"
              @update:value="user.address_line_2 = $event"
              label="Mailing Address 2"
              :errors="errors"
            />
          </div>
          <div class="form-group">
            <label for="city">City*</label>
            <InputField
              name="city"
              :value="user.city"
              @update:value="user.city = $event"
              label="City"
              :required="true"
              :errors="errors"
            />
          </div>

          <div class="location">
            <div class="form-group">
              <label for="">Country/State*</label>
              <div
                class="input-field"
                :class="{ 'has-error': hasError('country') }"
              >
                <select name="country" v-model="user.country">
                  <option value="">Country</option>
                  <option
                    v-for="(name, code) in countries"
                    :key="code"
                    :value="code"
                    >{{ name }}</option
                  >
                </select>
                <div class="errors">
                  <ul>
                    <li
                      v-for="error in errors.filter(
                        error => error.field == 'country'
                      )"
                      :key="error.field"
                    >
                      * {{ error.error }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="form-group state-field" v-if="user.country != 'USA'">
                <label for="state">State*</label>
                <InputField
                  :disabled="user.country == ''"
                  name="state"
                  :value="user.state"
                  @update:value="user.state = $event"
                  :label="stateLabel"
                  :required="true"
                  :errors="errors"
                />
              </div>
              <div
                v-if="user.country == 'USA'"
                class="input-field"
                :class="{ 'has-error': hasError('state') }"
              >
                <select name="state" v-model="user.state">
                  <option value="">State/Province</option>
                  <option
                    v-for="(state, index) in states"
                    :key="index"
                    :value="state.abbreviation"
                    >{{ state.name }}</option
                  >
                </select>
                <div class="errors">
                  <ul>
                    <li
                      v-for="error in errors.filter(
                        error => error.field == 'state'
                      )"
                      :key="error.field"
                    >
                      * {{ error.error }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="zip_code">Zip Code*</label>
              <InputField
                name="zip_code"
                :mask="zipMask"
                :value="user.zip_code"
                @update:value="user.zip_code = $event"
                label="Zip Code"
                :required="true"
                :errors="errors"
              />
            </div>
          </div>

          <div class="form-group">
            <label for="email_address">Email Address*</label>
            <InputField
              name="email_address"
              :value="user.email_address"
              @update:value="user.email_address = $event"
              label="Email Address"
              :required="true"
              :errors="errors"
            />
          </div>
          <div class="form-group">
            <label for="primary_phone">Primary Phone Number*</label>
            <InputField
              name="primary_phone"
              :mask="phoneMask"
              :value="user.primary_phone"
              @update:value="user.primary_phone = $event"
              label="Primary Phone Number"
              :required="true"
              :errors="errors"
            />
          </div>
          <div class="form-group">
            <label for="secondary_phone">Secondary Phone or Fax Number</label>
            <InputField
              name="secondary_phone"
              :mask="phoneMask"
              :value="user.secondary_phone"
              @update:value="user.secondary_phone = $event"
              label="Secondary Phone or Fax Number"
              :required="false"
              :errors="errors"
            />
          </div>
        </div>

        <div class="buttons">
          <button v-on:click="gotoStep(2)" class="button primary">NEXT</button>
        </div>
      </div>

      <div class="step payment-information" v-show="step == 2">
        <div class="group">
          <h3>Payment Information</h3>

          <div
            class="tax-category"
            :class="{ 'has-error': hasError('tax_entity_type') }"
          >
            <label
              v-for="(category, index) in taxCategories"
              :key="index"
              :for="category.id"
            >
              <input
                type="radio"
                :name="category.id"
                :id="category.id"
                v-model="user.tax_entity_type"
                :value="category.id"
              />
              {{ category.name }}
              <select
                v-if="
                  index == 5 &&
                    user.tax_entity_type == 'limitedliability-company'
                "
                v-model="user.taxClassification"
              >
                <option value="">Select Tax Classification</option>
                <option value="6">S Corporation</option>
                <option value="5">C Corporation</option>
                <option value="7">Partnership</option>
                <option value="8">Non Profit</option>
              </select>
              <span v-if="index == 7">
                (
                <a
                  href="https://www.irs.gov/pub/irs-pdf/fw8ben.pdf"
                  target="_blank"
                >
                  W-8BEN </a
                >,
                <a
                  href="https://www.irs.gov/pub/irs-pdf/fw8bene.pdf"
                  target="_blank"
                >
                  W-8BENE </a
                >)
              </span>
            </label>
          </div>

          <div class="form-group" v-if="user.tax_entity_type != 9">
            <label for="tax_id">{{ taxIdPlaceholder }}*</label>
            <InputField
              name="tax_id"
              :mask="taxIdMask"
              :value="user.tax_id"
              @update:value="user.tax_id = $event"
              :label="taxIdPlaceholder"
              :required="true"
              :errors="errors"
            />
          </div>

          <label for="payment_tax_id" v-if="user.tax_entity_type == 9">
            <div>
              As a foreign company or individual you are required to provide a
              completed and signed copy of W-8BEN or W-8BENE in order to be
              issued royalty payments.<br />
              Please contact
              <a href="mailto:clientservices@harryfox.com">
                ClientServices@harryfox.com
              </a>
              for submission instructions.
            </div>
          </label>

          <div class="form-group" v-if="user.tax_entity_type != 9">
            <label for="tax_name">Name on Tax Return*</label>
            <div class="w9-hint">
              <a href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" target="_blank">
                W-9 Instructions
              </a>
            </div>
            <InputField
              name="tax_name"
              :value="user.tax_name"
              @update:value="user.tax_name = $event"
              label="Name on Tax Return"
              :required="true"
              :errors="errors"
            />
          </div>

          <div class="w9-instructions">
            <br />
            <label for="w9-instructions">
              <input
                type="checkbox"
                id="w9-instructions"
                v-model="user.certified"
                :class="{ 'has-error': hasError('certified') }"
              />
              I certify that:
            </label>
            <ul>
              <li>The payee's TIN is correct,</li>
              <li>
                The payee is not subject to backup withholding due to failure to
                report interest and dividend income,
              </li>
              <li v-if="user.tax_entity_type != 9">
                The payee is a U.S. person, and
              </li>
              <li>
                The FATCA code entered on this form (if any) indicating that the
                payee is exempt from FATCA reporting is correct.
              </li>
            </ul>
          </div>

          <label for="authorized" class="authorized">
            <input
              type="checkbox"
              id="authorized"
              v-model="user.authorized"
              value="Y"
              :class="{ 'has-error': hasError('authorized') }"
            />
            I,
            <strong>
              <span>Full Name*</span>
              <InputField
                name="legal"
                :value="user.legal"
                @update:value="user.legal = $event"
                label="Full Name"
                :required="true"
                :errors="errors"
              />
            </strong>
            <strong>
              <span>Title*</span>
              <InputField
                name="title"
                :value="user.title"
                @update:value="user.title = $event"
                label="Title"
                :required="true"
                :errors="errors"
              />
            </strong>
            represent that I am authorized by the organization above to
            designate the person above as an Administrator to oversee our
            employees' access to HFA's online systems.
          </label>

          <label for="confirmed" class="confirmed">
            <input
              type="checkbox"
              id="confirmed"
              v-model="user.confirmed"
              value="Y"
              :class="{ 'has-error': hasError('confirmed') }"
            />
            I (on behalf of myself and any individual or entity I represent)
            acknowledge reviewing the
            <router-link :to="{ name: 'terms-of-use' }" target="_blank">
              Terms of Use
            </router-link>
            and agree to be bound by its terms. In addition, I agree:
            <ul>
              <li>
                To receive Notices of Intention to Obtain a Compulsory License
                (“NOIs”) under Section 115 of the Copyright Act, 17 U.S.C. §
                115, from The Harry Fox Agency by way of electronic delivery,
                whether email or posting to your online account. I understand
                that I may withdraw my consent for electronic delivery of NOIs
                by emailing
                <a href="mailto:clientservices@harryfox.com">
                  clientservices@harryfox.com </a
                >. Such withdrawal will be effective within thirty (30) days of
                receipt of the request.
              </li>
              <li>
                To receive monthly and annual statements of account from The
                Harry Fox Agency by way of electronic delivery, whether email or
                posting to your online account, except that payment will still
                be delivered to you in the form of a paper check unless you
                complete HFA’s Direct Deposit form.
              </li>
            </ul>
          </label>
        </div>
        <div class="error" v-if="errors.length > 0">
          You left some required fields unfilled.
        </div>
        <div class="buttons">
          <button v-on:click="gotoStep(1)" class="button default">BACK</button>
          <button v-on:click="submit()" class="button primary">SUBMIT</button>
        </div>
      </div>
    </div>

    <div class="popup" v-show="showPopup">
      <div class="body">
        <div class="become-affiliate">
          <h2>
            Take Your Royalties to the Next Level with an Upgrade to HFA
            Affiliated Publisher
          </h2>
          <span
            >As an affiliate, HFA will handle your U.S. mechanical licensing and
            worldwide royalty collections, data management, and YouTube™
            monetization.</span
          >

          <AffiliateBenefits />
        </div>

        <div class="buttons">
          <router-link :to="{ name: 'hfa-services' }" class="button primary"
            >APPLY TO BE AN HFA AFFILIATE TODAY</router-link
          >
          <router-link to="/" class="button default">Back to home</router-link>
        </div>
      </div>
    </div>

    <v-dialog @closed="focusInput" class="alert-modal" />
  </div>
</template>

<script>
import AffiliateBenefits from "@/components/AffiliateBenefits.vue";
import InputField from "@/components/InputField.vue";
import ApplicationSuccess from "@/views/ApplicationSuccess.vue";

import USA from "@/api/states.js";
import Countries from "@/api/countries.js";
import DataFormatter from "@/services/data-formatter.js";
import axios from "axios";

export default {
  name: "CreateHfaAccount",
  props: ["code"],
  components: {
    AffiliateBenefits,
    InputField
  },
  metaInfo: function() {
    return {
      title: " - Create HFA Account",
      meta: [
        {
          name: "description",
          content:
            "With your HFA Online Account you will be able to register your works, get paid and access royalty statements"
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  data: function() {
    return {
      alertShown: false,
      affiliate: false,
      step: 1,
      showPopup: false,
      errors: [],
      user: {
        company_name: null,
        hfa_account_number: null,
        ipi_number: null,
        business_title: null,
        address_line_1: null,
        address_line_2: null,
        city: null,
        country: "",
        state: "",
        legal: null,
        zip_code: null,
        email_address: null,
        primary_phone: null,
        secondary_phone: null,
        tax_entity_type: null,
        taxClassification: "",
        tax_id: null,
        tax_name: null,
        full_name: null,
        title: null,
        certified: false,
        authorized: false,
        confirmed: false
      },
      taxCategories: [
        { name: "Individual/Sole Proprietor", id: 1 },
        { name: "Single-Member LLC", id: 10 },
        { name: "C Corporation", id: 2 },
        { name: "S Corporation", id: 3 },
        { name: "Partnership", id: 4 },
        { name: "Limited Liability Company", id: "limitedliability-company" },
        { name: "Trust/State", id: 8 },
        { name: "W-8BEN", id: 9 },
        { name: "Non-Profit", id: 11 }
      ],
      countries: Countries.list,
      states: USA.states
    };
  },
  computed: {
    stateLabel: function() {
      if (this.user.country == "") {
        return "Select your country";
      } else {
        return "State";
      }
    },
    phoneMask: function() {
      if (this.user.country == "USA") {
        return "###-###-####";
      } else {
        return "########################################";
      }
    },
    zipMask: function() {
      if (this.user.country == "USA") {
        return "#####";
      } else {
        return "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX";
      }
    },
    taxIdMask: function() {
      if (this.user.tax_entity_type == 1) {
        return "###-##-####";
      } else if (this.user.tax_entity_type == null) {
        return "#########";
      } else {
        return "##-#######";
      }
    },
    taxIdPlaceholder: function() {
      if (this.user.tax_entity_type == 1) {
        return "Social Security # (SSN)";
      } else if (this.user.tax_entity_type == null) {
        return "Publisher Tax ID";
      } else {
        return "Employer Identification # (EIN)";
      }
    }
  },
  methods: {
    isRegister: function() {
      return this.$route.name == "register-hfa-account";
    },
    showSuccess: function() {
      this.$modal.show(
        ApplicationSuccess,
        {},
        {
          height: "auto",
          width: "80%",
          classes: "v--modal contact-form-modal"
        }
      );
    },
    focusInput: function() {
      document.getElementById("hfa_account_number").focus();
    },
    showAlert: function() {
      if (this.alertShown) {
        return false;
      }

      this.alertShown = true;

      this.$modal.show("dialog", {
        title: "Alert!",
        text:
          "Please note this form is intended for the creation of <b><u>NEW</u></b> HFA publisher accounts. If you already have an NON-AFFILIATE publisher account and would like to upgrade your account to an HFA AFFILIATE, please proceed and include your P# in the <i>HFA Publisher Account No.</i> field. <br> <br> If you are looking to make updates to your already existing HFA account, please contact Client Services directly at <a href='mailto:clientservices@harryfox.com'>clientservices@harryfox.com</a>.",
        buttons: [
          {
            title: "CLOSE"
          }
        ]
      });
    },
    gotoStep: function(step) {
      if (step == 2) {
        let publisherFields = [
          "company_name",
          "hfa_account_number",
          "firstname",
          "lastname",
          "business_title",
          "address_line_1",
          "address_line_2",
          "city",
          "country",
          "state",
          "zip_code",
          "email_address",
          "primary_phone",
          "secondary_phone"
        ];

        this.validateFields(publisherFields);

        if (this.user.ipi_number && this.user.ipi_number.length > 11) {
          this.errors.push({
            field: "ipi_number",
            error: "IPI # cannot be greater than 11 digits"
          });
        }

        if (!this.errors.length) {
          this.step = step;
        } else {
          return false;
        }
      } else {
        this.step = step;
      }
    },
    validateFields: function(fields) {
      this.errors = [];

      for (let key of fields) {
        let value = this.user[key];

        if (
          key == "hfa_account_number" ||
          (key == "taxClassification" &&
            this.user.tax_entity_type != "limitedliability-company") ||
          (key == "tax_id" && this.user.tax_entity_type == 9) ||
          (key == "tax_name" && this.user.tax_entity_type == 9) ||
          key == "address_line_2" ||
          key == "secondary_phone"
        ) {
          continue;
        }

        if (!value) {
          this.errors.push({ field: key, error: "Field Required" });
        }

        if (
          key == "email_address" &&
          !this.errors.find(error => error.field == "email_address")
        ) {
          let reg = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
          if (!reg.test(value)) {
            this.errors.push({ field: key, error: "Email is invalid" });
          }
        }

        if (
          key == "tax_id" &&
          value != null &&
          value.replace(/-/g, "").length < 9
        ) {
          this.errors.push({ field: key, error: "Invalid value" });
        }
      }
    },
    submit: function() {
      let paymentFields = [
        "tax_entity_type",
        "taxClassification",
        "tax_id",
        "tax_name",
        "legal",
        "title",
        "certified",
        "authorized",
        "confirmed"
      ];
      this.validateFields(paymentFields);

      if (!this.errors.length) {
        this.postUser();
      }
    },
    processFile: function(event) {
      this.user.w8benFile = event.target.files[0];
    },
    hasError: function(name) {
      return this.errors.filter(error => error.field == name).length;
    },
    postUser: function() {
      let userHash = DataFormatter.formatUser(this.user);
      let $this = this;

      if (this.processing === true) {
        return;
      }

      this.processing = true;

      if ($this.code !== undefined) {
        userHash.origin_link = $this.code;
      }

      axios
        .post(process.env.VUE_APP_API_URL, userHash)
        .then(function(response) {
          localStorage.setItem("user_id", parseInt(response.data.id));
          localStorage.setItem("user_email", $this.user.email_address);
          localStorage.setItem("HfaUser", JSON.stringify(userHash));

          if ($this.code !== undefined || $this.isRegister()) {
            axios.post(process.env.VUE_APP_API_CONTACT_URL, {
              email: $this.user.email_address,
              account_type: "non-affiliate"
            });
            $this.showPopup = true;
            $this.showSuccess();
          } else {
            $this.$router.push({ name: "apply-today" });
          }

          $this.processing = false;
        })
        .catch(function(error) {
          console.log("ERROR", error);
          $this.processing = false;
          $this.$router.go();
          alert("Sorry, something went wrong, please try again.");
        });
    }
  },
  mounted: function() {
    // console.log(this.$route.name);
  }
};
</script>

<style lang="scss">
#create-hfa-account {
  .popup {
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    height: 100%;
    width: 100%;

    .header {
      margin: 0;
    }
    .body {
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      // height: calc(100% - 56px);
      text-align: center;
      font-size: 1.5vw;
      background-color: #fff;

      > p {
        background-color: #000;
        color: #fff;
        padding: 1em;
        width: 95%;
        margin-bottom: 0;
      }
      .become-affiliate {
        width: 100%;

        .benefits-container {
          margin-top: 20px;
          justify-content: center;

          #benefits {
            margin-bottom: 0;

            .action {
              display: none;
            }
            .benefit {
              margin-bottom: 20px;
              img {
                width: 80px;
              }
              h2,
              p {
                font-size: 0.8em;
              }
            }
          }
        }
      }
      .buttons {
        margin-top: 0;
        display: block;

        .button {
          display: block;
          margin: 30px auto;

          &:first-child {
            width: 350px;
          }
          &:last-child {
            width: 200px;
            font-size: 1em;
          }
        }
      }
    }
  }

  .header {
    background: $blue;
    color: #fff;
    margin: -8px -8px 0 -8px;
    padding: 0.5em;

    @media (min-width: $desktop) {
      margin: 0 0 0 -50px;
      padding: 1em;
    }

    h1,
    h2 {
      color: #fff;
      margin: 0;
    }
    h1 {
      font-size: 1.2em;
      font-family: "Fjalla One", sans-serif;
      margin-bottom: 20px;
    }
    h2 {
      font-family: "Abel", sans-serif;
      font-size: 1em;
    }
    ul {
      list-style: none;
      margin: 0 0;
      padding-left: 0;

      li {
        line-height: 1.6;

        &:before {
          content: "+";
          margin-right: 5px;
        }
      }
    }
  }
  .body {
    @media (min-width: $desktop) {
      margin-left: -30px;
      margin-right: 20px;
    }

    .buttons {
      display: flex;
      margin: 20px auto;
      justify-content: center;

      .button {
        width: 150px;
        font-size: 1.2em;
        margin: 0 10px;
      }
    }
    .error {
      color: red;
      text-align: center;
      font-weight: bold;
      font-size: 0.9em;
      margin-bottom: -15px;
    }

    input[type="checkbox"].has-error {
      box-shadow: 0 0 0px 2px red inset;
    }

    .group {
      margin-bottom: 40px;
      margin-top: 20px;

      a {
        text-decoration: underline;
      }
      h3 {
        margin: 0;
        text-align: center;
      }
      .form-group {
        position: relative;

        input {
          @media (min-width: $desktop) {
            width: 60%;
          }
        }
        .tooltip {
          top: 33px;
          width: calc(100% - 20px);

          .arrow {
            position: absolute;
            top: -20px;
            left: 5px;
            width: 1px;
            height: 1px;
            background-color: transparent;
            border: 10px solid transparent;
            border-bottom-color: $blue;
          }

          a {
            color: #fff;
          }

          @media (min-width: $desktop) {
            width: 250px;
            left: calc(60% + 15px);
            top: 0;

            .arrow {
              top: 5px;
              left: -20px;
              border-color: transparent;
              border-right-color: $blue;
            }
          }
        }
        .hint {
          position: absolute;
          font-size: 0.8em;
          bottom: -15px;
          left: 0;

          @media (min-width: $desktop) {
            bottom: 0;
            left: 210px;
          }
        }

        &.state-field {
          margin: 0;

          label {
            width: auto;
          }
          input {
            width: 100%;
          }
        }

        .full-name {
          border: 1px solid #ccc;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 5px 10px 20px;
          width: calc(100% - 20px);
          border-radius: 3px;

          > * {
            margin: 0;
            width: auto;
          }
          .input-field {
            width: 35%;
            margin-right: 10px;

            input {
              width: calc(100% - 12px);
            }
          }

          @media (min-width: $desktop) {
            width: calc(51% - 20px);

            .input-field {
              width: 35%;
            }
          }
        }
      }

      select {
        background: none;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 1em;
        font-family: "Abel", sans-serif;
        padding: 0.2em;
        margin-right: 10px;
      }

      .location {
        // display: flex;
        // justify-content: flex-start;
        // align-items: flex-start;

        .input-field {
          width: 200px;
        }
      }
      label {
        display: block;
        margin-top: 5px;
        cursor: pointer;
      }

      .tax-category {
        margin-bottom: 20px;
        &.has-error {
          color: red;
        }
      }

      .w9-instructions {
        ul {
          list-style: decimal;
          padding-left: 20px;
          margin: 0 0 20px;
        }
      }

      .w9-hint {
        position: absolute;
        font-size: 0.8em;
        bottom: 10px;
        left: 110px;
      }

      .authorized {
        margin-top: 40px;
        line-height: 2.4em;

        > * {
          line-height: 1em;
        }

        strong {
          position: relative;
          display: inline-block;
          width: 80%;

          &:last-child {
            margin-top: 35px;
          }

          @media (min-width: $desktop) {
            display: inline;
            width: auto;
            margin-top: 0;
          }

          span {
            position: absolute;
            font-weight: normal;
            font-size: 0.9em;
            top: -22px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        .input-field {
          display: block;
          // width: 150px;
          margin: 0 5px;

          @media (min-width: $desktop) {
            display: inline-block;
            width: 200px;
          }

          input {
            border: 1px solid #999;
            border-radius: 3px;
          }
        }
        .input-field + .input-field {
          width: 150px;
        }
      }
    }
    .step.payment-information {
      h3 {
        text-align: left;
      }
      .form-group {
        label {
          width: 220px;
        }
      }
    }
  }

  .alert-modal {
    .vue-dialog {
      font-size: 1.5em;
      width: 100% !important;

      @media (min-width: $desktop) {
        width: 50% !important;
      }

      .dialog-content {
        font-size: 1em;

        .dialog-c-text {
          a {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
