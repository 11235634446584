<template>
  <div id="application-success">
    <div class="close" @click="$emit('close')">
      <img src="@/assets/delete.png" alt="close" />
    </div>
    <p><b>SUCCESS!!</b></p>
    <p>
      Your information has been submitted to create an HFA online account and
      your request is currently being validated and processed.
    </p>
    <p>
      Once validation is complete you will receive email confirmation with login
      credentials and instructions on how to complete your account registration.
    </p>
    <p v-if="!affiliate">Your Account Type is: NON-AFFILIATED PUBLISHER</p>
    <p v-else>Your Account Type is: AFFILIATED PUBLISHER</p>
    <p v-if="!affiliate">
      This level allows you to register your song data and access statements
      from HFA Rumblefish clients.
    </p>
    <p>
      **<i
        >If you have not received a confirmation email after 30 days, please
        contact
        <a href="mailto:clientservices@harryfox.com"
          >clientservices@harryfox.com</a
        >.</i
      >**
    </p>
  </div>
</template>

<script>
export default {
  name: "ApplicationSuccess",
  props: ["affiliate"]
};
</script>

<style lang="scss">
#application-success {
  text-align: center;
  padding: 10px;
  border-style: solid;
  border-color: $blue;
  border-width: 10px;

  @media (min-width: $desktop) {
    padding: 50px;
    width: 75vw;
    height: auto;
    font-size: 1.3em;
    background-color: white;
  }
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 2;
  img {
    width: 15px;
    height: 15px;
  }
}
</style>
